<template>
  <div>
    <Head />

    <div class="wrapper case-statistics" v-if="CaseStatisticsData">
      <section class="main">
        <div class="container">
          <div class="box">
            <h1>
              <img class="h1-img" src="/img/h1-statistics.svg" alt="統計資訊">統計資訊<span
                style="font-size: 1rem; margin-left: 5px">(搜尋區間最長三個月)</span>
            </h1>
            <div class="top-box">
              <ul class="btn-box">
                <li :class="DataArea == 1 ? 'active' : ''" @click="SetDataArea(1)">
                  <a href="#" title="日">
                    <p>日</p>
                  </a>
                </li>
                <li :class="DataArea == 2 ? 'active' : ''" @click="SetDataArea(2)">
                  <a href="#" title="周">
                    <p>周</p>
                  </a>
                </li>
                <li :class="DataArea == 3 ? 'active' : ''" @click="SetDataArea(3)">
                  <a href="#" title="月">
                    <p>月</p>
                  </a>
                </li>
              </ul>
              <div class="date-box">
                <!-- <label for="date"></label> -->
                <input id="date" type="date" v-model="StartDay" />
                <input id="date" type="date" v-model="EndDay" />
              </div>
              <div class="search">
                <div class="form-group" v-if="false">
                  <select class="form-control" id="SearchType" v-model="SearchType">
                    <option value="1">行政區</option>
                    <option value="2">機關別</option>
                  </select>
                </div>
                <div class="form-group" v-if="SearchType == 1">
                  <select class="form-control" id="Subj_District" v-model="Subj_District">
                    <option value="">全部行政區</option>
                    <option v-for="(Area, index) in CaseStatisticsData.Areas" :key="index" :text="Area.Name"
                      :value="Area.AreaId">
                      {{ Area.Name }}
                    </option>
                  </select>
                </div>
                <div class="form-group" v-if="SearchType == 2">
                  <select class="form-control" id="OrganName" v-model="OrganName">
                    <option value="">全部機關別</option>
                    <option v-for="(Organ, index) in CaseStatisticsData.Organs" :key="index" :text="Organ"
                      :value="Organ">
                      {{ Organ }}
                    </option>
                  </select>
                </div>
                <div class="btn" v-if="CTIId == -1">
                  <button @click="GetData(-1)">搜尋</button>
                </div>
                <div class="btn" v-if="CTIId != -1">
                  <button @click="GetData(-1)">重設</button>
                </div>
              </div>
            </div>
            <div class="statistics-box">
              <div class="statistics">
                <canvas id="countries" width="500" height="500"></canvas>
                <div class="text">
                  <h2>
                    案件數 <span>{{ Total }}</span>
                  </h2>
                  <p v-if="StartDay_R && EndDay_R">
                    {{ StartDay_R }} ~ {{ EndDay_R }}
                  </p>
                  <hr />
                  <p>各案件類別通報數量</p>
                </div>
              </div>
              <div class="text-box" v-if="Statistics">
                <ul>
                  <li v-for="(Statistic, index) in Statistics" :key="index"
                    :style="Statistic.Count == 0 ? 'opacity: 0.25;' : ''">
                    <a @click="
                        if (
                      Statistic.Count > 0 &&
                      (CTIId == -1 || CTIId == 18)
                    ) {
                      GetData(Statistic.CTIId);
                    }
                      " :style="Statistic.Count > 0 && (CTIId == -1 || CTIId == 18)
                        ? 'cursor: pointer;'
                        : ''
                        " :title="Statistic.Title">
                      {{ Statistic.Title }}
                    </a>
                    <div class="line" :style="'color: ' + Statistic.Color">
                      <span></span>
                    </div>
                    <p>
                      {{ Statistic.Count }} 件 /
                      {{
                        Total > 0
                          ? Math.round((Statistic.Count / Total) * 100)
                          : 0
                      }}%
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

export default {
  name: "CaseStatistics",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      CaseStatisticsData: null,
      DataArea: -1,
      Today: null,
      StartDay: null,
      EndDay: null,
      StartDay_D: null,
      EndDay_D: null,
      StartDay_W: null,
      EndDay_W: null,
      StartDay_M: null,
      EndDay_M: null,
      StartDay_R: null,
      EndDay_R: null,
      Subj_District: "",
      Statistics: null,
      Charts: null,
      Total: 0,
      CTIId: -1,
      Chart: null,
      SearchType: 1,
      OrganName: "",
    };
  },
  watch: {
    StartDay(newVal, oldVal) {
      if (
        this.DataArea == 1 &&
        (this.StartDay != this.StartDay_D || this.EndDay != this.EndDay_D)
      ) {
        this.DataArea = -1;
      } else if (
        this.DataArea == 2 &&
        (this.StartDay != this.StartDay_W || this.EndDay != this.EndDay_W)
      ) {
        this.DataArea = -1;
      } else if (
        this.DataArea == 3 &&
        (this.StartDay != this.StartDay_M || this.EndDay != this.EndDay_M)
      ) {
        this.DataArea = -1;
      }
    },
    EndDay(newVal, oldVal) {
      if (
        this.DataArea == 1 &&
        this.StartDay != this.StartDay_D &&
        this.EndDay != this.EndDay_D
      ) {
        this.DataArea = -1;
      } else if (
        this.DataArea == 2 &&
        this.StartDay != this.StartDay_W &&
        this.EndDay != this.EndDay_W
      ) {
        this.DataArea = -1;
      } else if (
        this.DataArea == 3 &&
        this.StartDay != this.StartDay_M &&
        this.EndDay != this.EndDay_M
      ) {
        this.DataArea = -1;
      }
    },
  },
  created: function () {
    var nowDate = new Date();
    var weerkDate = new Date(nowDate - 7 * 24 * 3600 * 1000);
    var monthDate = new Date(nowDate - 30 * 24 * 3600 * 1000);
    var year = nowDate.getFullYear();
    var month = nowDate.getMonth() + 1;
    var day = nowDate.getDate();
    // 結束日都是當下
    this.EndDay_D =
      year +
      "-" +
      (("" + month).length < 2 ? "0" : "") +
      month +
      "-" +
      (("" + day).length < 2 ? "0" : "") +
      day;
    this.EndDay_W =
      year +
      "-" +
      (("" + month).length < 2 ? "0" : "") +
      month +
      "-" +
      (("" + day).length < 2 ? "0" : "") +
      day;
    this.EndDay_M =
      year +
      "-" +
      (("" + month).length < 2 ? "0" : "") +
      month +
      "-" +
      (("" + day).length < 2 ? "0" : "") +
      day;
    // 日
    this.StartDay_D =
      year +
      "-" +
      (("" + month).length < 2 ? "0" : "") +
      month +
      "-" +
      (("" + day).length < 2 ? "0" : "") +
      day;
    // 週
    year = weerkDate.getFullYear();
    month = weerkDate.getMonth() + 1;
    day = weerkDate.getDate();
    this.StartDay_W =
      year +
      "-" +
      (("" + month).length < 2 ? "0" : "") +
      month +
      "-" +
      (("" + day).length < 2 ? "0" : "") +
      day;
    // 月
    year = monthDate.getFullYear();
    month = monthDate.getMonth() + 1;
    day = monthDate.getDate();
    this.StartDay_M =
      year +
      "-" +
      (("" + month).length < 2 ? "0" : "") +
      month +
      "-" +
      (("" + day).length < 2 ? "0" : "") +
      day;
    // 預設
    this.StartDay = this.StartDay_D;
    this.EndDay = this.EndDay_D;
    this.DataArea = 1;

    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/CaseStatistics",
        {
          params: {},
        }
      )
      .then((response) => {
        this.CaseStatisticsData = response.data;
        this.GetData(-1);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () { },
  methods: {
    SetDataArea(area) {
      if (area == 1) {
        this.StartDay = this.StartDay_D;
        this.EndDay = this.EndDay_D;
      } else if (area == 2) {
        this.StartDay = this.StartDay_W;
        this.EndDay = this.EndDay_W;
      } else if (area == 3) {
        this.StartDay = this.StartDay_M;
        this.EndDay = this.EndDay_M;
      }
      this.DataArea = area;
    },
    GetData(CTIId) {
      this.CTIId = CTIId;
      var that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/GetCaseStatistics",
        type: "post",
        data: {
          StartDay: that.StartDay,
          EndDay: that.EndDay,
          SearchType: that.SearchType,
          Subj_District: that.Subj_District,
          OrganName: that.OrganName,
          CTIId: that.CTIId,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.status == 1) {
            that.Statistics = res.Statistics;
            that.Charts = res.Charts;
            that.StartDay_R = res.StartDay;
            that.EndDay_R = res.EndDay;
            that.Total = res.Total;

            // 清空
            if (that.Chart) {
              that.Chart.destroy();
            }
            // get pie chart canvas
            var canvas = document.getElementById("countries");
            var context = canvas.getContext("2d");
            // draw pie chart
            if (that.Charts) {
              that.Chart = new Chart(context).Pie(that.Charts, {
                percentageInnerCutout: 85,
                animationSteps: 30,
                animationEasing: "none",
              });
            }
          } else {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
  },
};
</script>
