<template>
  <div>
    <Head />

    <!-- 網站導覽 -->
    <div class="wrapper api form">
      <section class="breadcrumb-box">
        <div class="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/" title="首頁">首頁</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                API說明
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section class="main">
        <div class="container">
          <img class="h1-img" src="/img/h1-api.svg" alt="API說明" />
          <h1>API說明</h1>
          <ul class="btn-box">
            <li class="">
              <a href="#" title="技術指南">
                技術指南
              </a>
            </li>
            <li>
              <a href="#" title="使用說明">
                使用說明
              </a>
            </li>
            <li>
              <a href="#" title="常見問題">
                常見問題
              </a>
            </li>
            <li>
              <a href="#" title="隱私權保護政策">
                隱私權保護政策
              </a>
            </li>
          </ul>
          <ul class="text-box">
            <li class="text">
              <h2>技術指南</h2>
              <div class="">
                <div class="first">
                  <h2>1.提供批次查詢案件的內容及處理狀況</h2>
                  <div>
                    <p>URL</p>
                    <p>https://[server_ip]/apisearch</p>
                  </div>
                  <div>
                    <p>資料格式</p>
                    <p>JSON</p>
                  </div>
                  <div>
                    <p>HTTP Method</p>
                    <p>POST</p>
                  </div>
                  <div>
                    <p>是否需要API Key</p>
                    <p>是</p>
                  </div>
                </div>
                <div class="second">
                  <h2>2.傳入參數</h2>
                  <div class="title-box">
                    <div class="title">
                      <p>參數名稱<br />Field Name</p>
                      <p>說明<br />Description</p>
                      <p>是否為必填<br />Required</p>
                      <p>備註<br />Notes</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>api_key</p>
                    </div>
                    <div>
                      <p>說明<br />Description</p>
                      <p>API KEY</p>
                    </div>
                    <div>
                      <p>是否為必填<br />Required</p>
                      <p>是</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>需申請取得</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>city_id</p>
                    </div>
                    <div>
                      <p>說明<br />Description</p>
                      <p>城市識別代碼</p>
                    </div>
                    <div>
                      <p>是否為必填<br />Required</p>
                      <p>是</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>tainan.gov.tw</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>service_request_id</p>
                    </div>
                    <div>
                      <p>說明<br />Description</p>
                      <p>案件編號</p>
                    </div>
                    <div>
                      <p>是否為必填<br />Required</p>
                      <p>否</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>
                        1.多筆案件需用逗號分隔
                        <br />
                        2.如果有輸入此欄位值，則忽略其它欄位值
                      </p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>service_code</p>
                    </div>
                    <div>
                      <p>說明<br />Description</p>
                      <p>案件類型識別碼</p>
                    </div>
                    <div>
                      <p>是否為必填<br />Required</p>
                      <p>否</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>
                        1.預設全部案件類型
                        <br />
                        2.多筆類型需用逗號分隔
                      </p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>start_date</p>
                    </div>
                    <div>
                      <p>說明<br />Description</p>
                      <p>案件通報時間 起時</p>
                    </div>
                    <div>
                      <p>是否為必填<br />Required</p>
                      <p>否</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>
                        如果沒有輸入，預設範圍為10天
                        <br />
                        格式：2010-01-01 00:00:00
                      </p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>end_date</p>
                    </div>
                    <div>
                      <p>說明<br />Description</p>
                      <p>案件通報時間 迄時</p>
                    </div>
                    <div>
                      <p>是否為必填<br />Required</p>
                      <p>否</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>
                        如果沒有輸入，預設範圍為10天
                        <br />
                        格式：2010-01-01 00:00:00
                      </p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>status</p>
                    </div>
                    <div>
                      <p>說明<br />Description</p>
                      <p>案件狀態</p>
                    </div>
                    <div>
                      <p>是否為必填<br />Required</p>
                      <p>否</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>
                        1.如果沒有輸入，預設全部狀態的案件
                        <br />
                        2.已通報(1)、處理中(3)、已結案(4)
                      </p>
                    </div>
                  </div>
                </div>
                <div class="third second">
                  <h2>3.輸出參數</h2>
                  <div class="title-box">
                    <div class="title">
                      <p>參數名稱<br />Field Name</p>
                      <p>參數說明<br />Description</p>
                      <p>備註<br />Notes</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>service_request_id</p>
                    </div>
                    <div>
                      <p>參數說明<br />Description</p>
                      <p>案件編號</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>唯一值不可重覆</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>status</p>
                    </div>
                    <div>
                      <p>參數說明<br />Description</p>
                      <p>案件狀態</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>已通報、處理中、已結案</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>status_notes</p>
                    </div>
                    <div>
                      <p>參數說明<br />Description</p>
                      <p>案件狀態說明</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>詳細說明案件的處理內容</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>area</p>
                    </div>
                    <div>
                      <p>參數說明<br />Description</p>
                      <p>區域</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>案件發生區域</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>service_name</p>
                    </div>
                    <div>
                      <p>參數說明<br />Description</p>
                      <p>類型</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>案件類型</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>service_sub_name</p>
                    </div>
                    <div>
                      <p>參數說明<br />Description</p>
                      <p>子類型</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>案件子類型</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>requested_datetime</p>
                    </div>
                    <div>
                      <p>參數說明<br />Description</p>
                      <p>案件建立時間</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>案件於資料庫建立時間</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>address</p>
                    </div>
                    <div>
                      <p>參數說明<br />Description</p>
                      <p>地址</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>案件發生地點</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>description</p>
                    </div>
                    <div>
                      <p>參數說明<br />Description</p>
                      <p>通報內容</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>通報時填寫的通報內容</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>lat</p>
                    </div>
                    <div>
                      <p>參數說明<br />Description</p>
                      <p>經度</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>案件發生地點經度</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>lng</p>
                    </div>
                    <div>
                      <p>參數說明<br />Description</p>
                      <p>緯度</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>案件發生地點緯度</p>
                    </div>
                  </div>
                  <div class="box">
                    <div class="one">
                      <p>參數名稱<br />Field Name</p>
                      <p>expected_datetime</p>
                    </div>
                    <div>
                      <p>參數說明<br />Description</p>
                      <p>案件預計完成時間</p>
                    </div>
                    <div>
                      <p>備註<br />Notes</p>
                      <p>格式：2010-01-01 00:00:00</p>
                    </div>
                  </div>
                </div>
                <div class="fourth">
                  <h2>4.可能回傳的錯誤訊息</h2>
                  <div class="">
                    401 - city_id未輸入
                    <br />
                    404 - city_id不正確
                    <br />
                    400 - 一般網路服務錯誤，於處理過程中產生的各種可能問題。
                  </div>
                </div>
                <div class="fifth">
                  <h2>5.範例</h2>
                  <ul class="">
                    <li>
                      <p>輸入範例</p>
                      <div>
                        <pre>
{
    "api_key":"需申请",
    "city_id":"tainan.gov.tw",
    "service_request_id":"A-TB-2023-00000",
    "service_code":"",
    "start_date":"",
    "end_date":"",
    "status":""
}
                                                </pre>
                      </div>
                    </li>
                    <li>
                      <p>輸出範例</p>
                      <div>
                        <pre>
{
    "RetCode":"1",
    "count":1,
    "data":
    [
        {
            "service_request_id": "A-TB-2023-00000",
            "status": "已通報",
            "status_notes": "",
            "area": "安平區",
            "service_name": "其他通報",
            "service_sub_name": "建築管理及使用類",
            "requested_datetime": "2023-01-01 01:01:01",
            "address": "永華路二段6號",
            "description": "",
            "lat": 22.9920584,
            "lng": 120.1826127,
            "expected_datetime": "2023-01-01 01:01:01"
        }
    ]
}
                                                </pre>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="text">
              <h2>使用說明</h2>
              <div class="font-box">
                <p>您如需新增案件(如開發APP協力通報)，請填寫以下表單。</p>
                <div class="container">
                  <div class="box">
                    <h2>聯絡資訊</h2>
                    <div class="option-box">
                      <label for="name" style="min-width: 150px">姓名</label>
                      <input id="name" v-model="name" class="" type="text" placeholder="請輸入姓名" />
                    </div>
                    <div class="option-box">
                      <label for="email" style="min-width: 150px">E-Mail</label>
                      <input id="email" v-model="email" class="" type="text" placeholder="請輸入E-Mail" />
                    </div>
                    <div class="option-box">
                      <label for="phone" style="min-width: 150px">電話</label>
                      <input id="phone" v-model="phone" class="" type="text" placeholder="請輸入電話" />
                    </div>
                    <div class="option-box">
                      <label for="company" style="min-width: 150px">公司或機構名稱</label>
                      <input id="company" v-model="company" class="" type="text" placeholder="請輸入公司或機構名稱" />
                    </div>
                  </div>
                  <div class="box">
                    <h2>應用程式資訊</h2>
                    <div class="option-box">
                      <label for="appName" style="min-width: 150px">應用程式名稱</label>
                      <input id="appName" v-model="appName" class="" type="text" placeholder="請輸入應用程式名稱" />
                    </div>
                    <div class="option-box">
                      <label for="appUrl" style="min-width: 150px">應用程式URL</label>
                      <input id="appUrl" v-model="appUrl" class="" type="text" placeholder="請輸入應用程式URL" />
                    </div>
                  </div>
                  <div class="box">
                    <h2>適用範圍</h2>
                    <p>
                      以下的隱私權保護政策，適用於您在臺南市民服務平台（http://1999.tainan.gov.tw/）活動時，所涉及有關個人資料收集、運用與保護。
                    </p>
                  </div>
                  <div class="box">
                    <h2>個人資料收集方式</h2>
                    <p>
                      當您進入本臺南市民服務平台新增或查詢案件時，依據「臺南市政府及所屬機關處理人民陳情作業要點」，需請您提供姓名、電話、電子信箱、性別及住址等個人資訊，限於公務處理使用，若您選擇不提供或提供非正確資料，受理機關有權不予處理；若是您冒名檢舉要自行負擔法律責任。
                    </p>
                  </div>
                  <div class="box">
                    <h2>個人資料的運用方式</h2>
                    <p>
                      本網站不會出售、出租或任意交換任何您的個人資料給其他團體或個人。只有在統計與分析之需求的前提下，本網站根據伺服器日誌文件，對使用者的人數和行為進行內部研究。此研究是根據全體使用者的資料進行統計分析與整理，而所有的公開資訊或分析報告，將僅對全體使用者行為總和進行分析及公佈，並不會提供特定對象個別資料之分析報告。
                    </p>
                  </div>
                  <div class="box">
                    <h2>資料的分享與公開方式</h2>
                    <p>
                      本網站不會任意出售、交換、或出租任何您的個人資料給其他團體或個人。只有在以下狀況會在本政策原則之下，與第三者共用您的個人資料︰
                    </p>
                    <p>
                      本網站有義務保護其網友隱私及個人資料，非經您本人同意不會自行修改、刪除或提供任何（或部份）使用者個人資料。除非經過您事先同意或符合以下情況：
                    </p>
                    <p>（1）中華民國司法檢調單位透過合法程序進行調閱。</p>
                    <p>（2）違反網站相關規章且已造成脅迫性。</p>
                    <p>（3）基於主動衍伸政府網站服務效益之考量。</p>
                    <p>（4）保護其他網友之合法使用權益。</p>
                  </div>
                  <div class="box">
                    <h2>同意使用條款</h2>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="agreeReg" value="1" v-model="agreeReg" />
                      <label class="form-check-label" for="agreeReg">我已詳讀上述隱私權保護政策，並接受所有Open API
                        的使用條款</label>
                    </div>
                  </div>
                  <div class="box">
                    <h2>驗證碼</h2>
                    <div class="form-group">
                      <div class="code-box">
                        <input class="form-control" placeholder="驗證碼" v-model="identifyCode_input" />
                        <div class="code">
                          <img :src="identifyCodeData" width="100" height="30" />
                          <a href="javascript:void(0)" @click="refreshCode()"><img src="/img/icon-rotate.svg"
                              alt="重新取得驗證碼" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="btn-box">
                    <button type="button" @click="SendApply">
                      送　出
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li class="text">
              <h2>常見問題</h2>
              <ul class="font-box">
                <li>
                  <p>
                    <span>Q</span>平台資料之取得是否免費使用？授權的方式為何？
                  </p>
                  <p>
                    平台開放之資料集以免費為原則，以無償及非專屬授權方式授權使用者得不限時間及地域，重製、改作、編輯、公開傳輸或為其他利用方式，開發各種產品或服務，提供公眾使用，無須取得各資料提供機關之書面或其他方式授權。
                  </p>
                </li>
                <li>
                  <p><span>Q</span>為何回傳的參數中文字為亂碼？</p>
                  <p>
                    叫及返回參數資料採用國際通用編碼格式「UTF-8」，因此使用Open
                    API傳接參數時將編碼調整為「UTF-8」格式即可。
                  </p>
                </li>
                <li>
                  <p>
                    <span>Q</span>我使用臺南市民服務平台通報的案件資料會被別人看到嗎?
                  </p>
                  <p>
                    是的，部分資料會被看到。
                    <br />
                    使用網頁或撥打1999通報案件，未來都將於臺南市民服務平台公布案件資料，包含了立案日期、結案日期、案件類別、描述、地點等資訊，以及您所上傳的照片。然而，有關個人資料的部分，我們將依「個人資料保護法」規定，不會在該網站上公開。
                  </p>
                </li>
                <li v-if="false">
                  <p><span>Q</span>我使用臺南市民服務平台撥打1999要收費嗎?</p>
                  <p>
                    是的。臺南1999是付費電話，行動電話撥打1999費率介於每分鐘2.88~3.6元（原則以秒計費）。
                  </p>
                </li>
              </ul>
            </li>
            <li class="text">
              <h2>隱私權保護政策</h2>
              <ul class="font-box">
                <li>
                  <p>適用範圍</p>
                  <p>
                    以下的隱私權保護政策，適用於您在臺南市民服務平台（http://1999.tainan.gov.tw/）活動時，所涉及有關個人資料收集、運用與保護。
                  </p>
                </li>
                <li>
                  <p>個人資料收集方式</p>
                  <p>
                    當您進入本臺南市民服務平台新增或查詢案件時，依據「臺南市政府及所屬機關處理人民陳情作業要點」，需請您提供姓名、電話、電子信箱、性別及住址等個人資訊，限於公務處理使用，若您選擇不提供或提供非正確資料，受理機關有權不予處理；若是您冒名檢舉要自行負擔法律責任。
                  </p>
                </li>
                <li>
                  <p>個人資料的運用方式</p>
                  <p>
                    本網站不會出售、出租或任意交換任何您的個人資料給其他團體或個人。只有在統計與分析之需求的前提下，本網站根據伺服器日誌文件，對使用者的人數和行為進行內部研究。此研究是根據全體使用者的資料進行統計分析與整理，而所有的公開資訊或分析報告，將僅對全體使用者行為總和進行分析及公佈，並不會提供特定對象個別資料之分析報告。
                  </p>
                </li>
                <li>
                  <p>資料的分享與公開方式</p>
                  <p>
                    本網站不會任意出售、交換、或出租任何您的個人資料給其他團體或個人。只有在以下狀況會在本政策原則之下，與第三者共用您的個人資料︰
                    <br />
                    本網站有義務保護其網友隱私及個人資料，非經您本人同意不會自行修改、刪除或提供任何（或部份）使用者個人資料。除非經過您事先同意或符合以下情況：
                    <br />
                    （1）中華民國司法檢調單位透過合法程序進行調閱。
                    <br />
                    （2）違反網站相關規章且已造成脅迫性。
                    <br />
                    （3）基於主動衍伸政府網站服務效益之考量。
                    <br />
                    （4）保護其他網友之合法使用權益。
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";
import { VueRecaptcha } from "vue-recaptcha";
import Sidentify from "@/components/Identify";

export default {
  name: "API",
  components: {
    Head,
    Footer,
    VueRecaptcha,
    Sidentify,
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      company: "",
      appName: "",
      appUrl: "",
      agreeReg: "",
      Verify: false,
      identifyCode: "0511",
      identifyCode_input: "",
      identifyCodeData: "",
      //identifyCodes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      //identifyCode_sound: ["0.mp3", "5.mp3", "1.mp3", "1.mp3"],
      num: 0,
    };
  },
  created: function () {
    // Vue.axios
    //   .get(
    //     this.$store.state.src +
    //       this.$store.state.subDirectory +
    //       "/Html/CaseAdd",
    //     {
    //       params: {
    //         CTIId: this.$route.params.CTIId,
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     this.CaseAddData = response.data;
    //     if (this.CaseAddData.CaseType.VerifiedType != 1) {
    //       this.Sugg_ReplyType = this.CaseAddData.CaseType.VerifiedType;
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    this.refreshCode();
  },
  mounted: function () {
    // API說明 按鈕切換
    $(".api .main .btn-box li").eq(0).addClass("active");
    $(".api .main .text-box .text").eq(0).addClass("show");

    $(".api .main .btn-box li").click(function () {
      var N = $(this).index();

      $(".api .main .btn-box li").removeClass("active");
      $(this).addClass("active");

      $(".api .main .text-box .text").removeClass("show");
      $(this).parents(".btn-box").siblings().children().eq(N).addClass("show");
    });
  },
  methods: {
    SendApply() {
      if (this.name == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入姓名",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.email == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入E-Mail",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      } else if (!Validate.reg_email(this.email)) {
        Swal.fire({
          title: "錯誤",
          text: "E-Mail格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.phone == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入電話",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.company == "") {
        Swal.fire({
          title: "錯誤",
          text: "公司或機構名稱",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.appName == "") {
        Swal.fire({
          title: "錯誤",
          text: "應用程式名稱",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.appUrl == "") {
        Swal.fire({
          title: "錯誤",
          text: "應用程式URL",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.agreeReg != "1") {
        Swal.fire({
          title: "錯誤",
          text: "請勾選同意使用條款",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 顯示請稍候
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 開始送出
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/ApiApply",
        type: "post",
        data: {
          name: that.name,
          email: that.email,
          phone: that.phone,
          company: that.company,
          appName: that.appName,
          appUrl: that.appUrl,
          agreeReg: that.agreeReg,
          identifyCode_input: that.identifyCode_input,
          identifyCode: that.identifyCode,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            Swal.fire({
              title: "送出成功",
              text: "您的填寫順序為:" + res.Number,
              icon: "success",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
              willClose: () => {
                location.reload();
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.identifyCodeData = "";
      this.makeCode();
    },
    makeCode() {
      // for (let i = 0; i < l; i++) {
      //   var code =
      //     this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      //   this.identifyCode += code;
      //   this.identifyCode_sound[i] = code + ".mp3";
      // }
      let that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/GetIdentifyCode",
        type: "post",
        data: {
          //token: $cookies.get("open_login_token"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            that.identifyCode = res.identifyCode;
            that.identifyCodeData = res.identifyCodeData;
          }
        },
      });
    },
    // playCode() {
    //   var sound_path = "/voice/numvoice/zh";
    //   var audio = new Audio(
    //     sound_path + "/" + this.identifyCode_sound[this.num]
    //   );
    //   audio.play();
    //   let that = this;
    //   audio.addEventListener(
    //     "ended",
    //     function () {
    //       that.num++;
    //       if (that.num < 4) that.playCode();
    //       if (that.num >= 4) that.num = 0;
    //     },
    //     false
    //   );
    // },
  },
};
</script>
