<template>
  <div>
    <Head />

    <!-- 熱點地圖 -->
    <div class="wrapper member case-search map-search" v-if="MapSearchData">
      <section class="breadcrumb-box">
        <div class="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/" title="首頁">首頁</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                熱點地圖
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section class="main">
        <div class="container">
          <div class="box" style="display: block">
            <img class="h1-img" src="/img/h1-map.svg" alt="熱點地圖" />
            <h1>熱點地圖</h1>
            <!-- 搜尋欄 -->
            <div class="searth-box">
              <div class="searth">
                <div class="form-group">
                  <select
                    class="form-control"
                    id="Subj_District"
                    v-model="areaCoordinate"
                  >
                    <option value="">行政區</option>
                    <option
                      v-for="(Area, index) in MapSearchData.Areas"
                      :key="index"
                      :text="Area.Name"
                      :value="Area.Lat + ',' + Area.Lng + ',' + Area.AreaId"
                    >
                      {{ Area.Name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <select class="form-control" v-model="CTIId">
                    <option value="-1">全部</option>
                    <option
                      v-for="(CaseType, index) in MapSearchData.CaseTypes"
                      :key="index"
                      :value="CaseType.CTIId"
                    >
                      {{ CaseType.Title }}
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <p>僅顯示三天內處理中案件</p>
              </div>
            </div>
            <!-- 地圖 -->
            <div class="map" style="position: relative">
              <GmapMap
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: true,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUi: false,
                  styles: [
                    {
                      featureType: 'poi',
                      stylers: [{ visibility: 'off' }],
                    },
                    {
                      featureType: 'transit',
                      stylers: [{ visibility: 'off' }],
                    },
                  ],
                }"
                ref="mapRef"
                :zoom="15"
                style="width: 100%; height: 500px"
                :center="{ lat: cp_lat, lng: cp_lng }"
                @idle="updateCenter"
              >
                <GmapMarker
                  v-if="mark_position != null"
                  :position="mark_position"
                  :clickable="true"
                  :draggable="false"
                  @click="center = mark_position"
                  :icon="{
                    url: '/img/map-people-new.svg',
                  }"
                ></GmapMarker>
                <GmapMarker
                  :key="index"
                  v-for="(Case, index) in Cases"
                  :position="{
                    lat: parseFloat(Case.Subj_Latitude),
                    lng: parseFloat(Case.Subj_Longitude),
                  }"
                  :clickable="true"
                  :draggable="false"
                  @click="toggleWindow(index)"
                  ><GmapInfoWindow :opened="window_open === index">
                    <div>
                      <p>類　　型：{{ Case.Subj_Item_Text }}</p>
                      <p v-if="Case.Subj_Subitem_Text">
                        事　　項：{{ Case.Subj_Subitem_Text }}
                      </p>
                      <p>地　　點：{{ Case.Subj_Location }}</p>
                      <p v-if="Case.Status == 1">案件狀態：已通報</p>
                      <p v-if="Case.Status == 2">案件狀態：通報中</p>
                      <p v-if="Case.Status == 3">案件狀態：處理中</p>
                      <p v-if="Case.Status == 4">案件狀態：已結案</p>
                    </div>
                  </GmapInfoWindow></GmapMarker
                >
              </GmapMap>
              <button
                style="
                  position: absolute;
                  right: 10px;
                  bottom: 110px;
                  z-index: 99;
                  width: 41px;
                  height: 41px;
                  background-color: white;
                  border: solid 1px;
                  border-color: #e9e9e9;
                  border-radius: 2px;
                  background-image: url(./img/myLocation.png);
                  background-size: contain;
                "
                @click="gotoMyLocation()"
              ></button>
            </div>
            <!-- 案件 -->
            <ul class="case-box" v-if="false">
              <li class="">
                <!-- 大img -->
                <div class="circle">
                  <div>
                    <img src="/img/case-progress4.svg" alt="案件資訊" />
                  </div>
                </div>
                <!-- 文字 -->
                <div class="text-box">
                  <div>
                    <p>行政區</p>
                    ：
                    <p>安南區</p>
                  </div>
                  <div>
                    <p>地點</p>
                    ：
                    <p>永華路二段149號</p>
                  </div>
                  <div>
                    <p>類型</p>
                    ：
                    <p>噪音舉發</p>
                  </div>
                  <div>
                    <p>事項</p>
                    ：
                    <p>妨害安寧</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
    <!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    //key: "AIzaSyBmY4-9JtWZoxAcuRZTGT5NrzZsyJTmQ-o",
    key: "AIzaSyAbIDDWw8Bl5Y1JSp6YiV_rcLH-3gtv-68",
    libraries: "places",
  },
  installComponents: true,
});

export default {
  name: "MapSearch",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      MapSearchData: null,
      areaCoordinate: "",
      CTIId: -1,
      cp_lat: 22.994088125212247,
      cp_lng: 120.18488458273286,
      AreaId: "",
      isFirstLoading: true,
      center_position: null,
      Cases: null,
      window_open: -1,
      s_lat: 0,
      s_lng: 0,
      e_lat: 0,
      e_lng: 0,
      mark_position: null,
      deniedGeolocation: false,
    };
  },
  watch: {
    areaCoordinate(newVal, oldVal) {
      if (newVal != "") {
        var latlng = newVal.split(",");
        this.cp_lat = parseFloat(latlng[0]);
        this.cp_lng = parseFloat(latlng[1]);
        this.AreaId = parseFloat(latlng[2]);
      } else {
        this.AreaId = "";
      }
      this.updateCases();
    },
    CTIId(newVal, oldVal) {
      this.updateCases();
    },
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/MapSearch",
        {
          params: {},
        }
      )
      .then((response) => {
        this.MapSearchData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    this.updateCases();
  },
  mounted: function () {
    // 清空座標
    $cookies.remove("op_lat");
    $cookies.remove("op_lon");
    // 迴圈直到取得位置
    var tryTime = 0;
    let that = this;
    var loopLocal = window.setInterval(function () {
      if ($cookies.isKey("op_lat") && $cookies.isKey("op_lon")) {
        var lat = $cookies.get("op_lat");
        var lon = $cookies.get("op_lon");
        that.mark_position = {
          lat: parseFloat(lat),
          lng: parseFloat(lon),
        };
        if (that.isFirstLoading) {
          that.$refs.mapRef.$mapPromise.then((map) => {
            map.setCenter(
              new google.maps.LatLng(
                that.mark_position.lat,
                that.mark_position.lng
              )
            );
            map.setZoom(15);
          });
          that.isFirstLoading = false;
        }
      } else {
        tryTime += 1;
        if (tryTime >= 5) {
          $cookies.set("op_lat", 22.994088125212247);
          $cookies.set("op_lon", 120.18488458273286);
        }
      }
    }, 1000);
    navigator.geolocation.watchPosition(
      function (position) {
        that.deniedGeolocation = false;
        $cookies.set("op_lat", position.coords.latitude);
        $cookies.set("op_lon", position.coords.longitude);
        console.log(
          position.coords.latitude + ", " + position.coords.longitude
        );
      },
      function (error) {
        console.log("code: " + error.code + ", " + "message: " + error.message);
        if (error.code == 1) {
          that.deniedGeolocation = true;
        }
      }
    );
  },
  updated: function () {},
  methods: {
    updateCenter(center) {
      this.$refs.mapRef.$mapPromise.then((map) => {
        this.center_position = map.getCenter();
        this.s_lat = map.getBounds().getSouthWest().lat();
        this.s_lng = map.getBounds().getSouthWest().lng();
        this.e_lat = map.getBounds().getNorthEast().lat();
        this.e_lng = map.getBounds().getNorthEast().lng();
        //this.updateCases();
      });
    },
    toggleWindow(index) {
      this.window_open = this.window_open === index ? null : index;
    },
    updateCases() {
      // 查詢附近案件
      Vue.axios
        .get(
          this.$store.state.src +
            this.$store.state.subDirectory +
            "/Html/MapSearchList",
          {
            params: {
              AreaId: this.AreaId,
              CTIId: this.CTIId,
            },
          }
        )
        .then((response) => {
          //this.CaseInfo = null;
          this.window_open = null;
          this.Cases = response.data.Cases;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gotoMyLocation() {
      if (this.deniedGeolocation) {
        Swal.fire({
          title: "無法存取位置資訊",
          html: "請確認您的瀏覽器設定<br />允許本站存取您的位置資訊後再試一次<br />(允許存取後請重新整理此頁面)",
          icon: "info",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
      } else {
        let that = this;
        this.$refs.mapRef.$mapPromise.then((map) => {
          map.setCenter(
            new google.maps.LatLng(
              that.mark_position.lat,
              that.mark_position.lng
            )
          );
          map.setZoom(15);
        });
      }
    },
  },
};
</script>

