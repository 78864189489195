<template>
  <div>
    <Head />

    <!-- 會員 個人通報案件狀況 -->
    <div class="wrapper member">
      <section class="breadcrumb-box">
        <div class="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/" title="首頁">首頁</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                會員案件紀錄
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section class="main">
        <div class="container">
          <!-- 左側邊會員按鈕 -->
          <ul class="member-btn">
            <li>
              <router-link to="/MemberCase" tag="button" class="">
                <img src="/img/member-case.svg" alt="案件紀錄" />
                案件紀錄
              </router-link>
            </li>
            <li>
              <router-link to="/MemberInfo" tag="button">
                <img src="/img/member-information.svg" alt="會員資料" />
                會員資料
              </router-link>
            </li>
          </ul>
          <!-- 右邊框框 -->
          <div class="box" style="display: block">
            <h1>個人通報案件狀況</h1>
            <!-- 案件分類按鈕 -->
            <ul class="btn-box">
              <li>
                <button
                  @click="Status = ''"
                  :class="Status == '' ? 'active' : ''"
                >
                  全部
                </button>
              </li>
              <li>
                <button
                  @click="Status = '3'"
                  :class="Status == '3' ? 'active' : ''"
                >
                  處理中
                </button>
              </li>
              <li>
                <button
                  @click="Status = '4'"
                  :class="Status == '4' ? 'active' : ''"
                >
                  結案
                </button>
              </li>
            </ul>
            <ul class="case-box show" v-if="Cases">
              <!-- 第一個 -->
              <li v-for="(Case, index_case) in Cases" :key="index_case">
                <!-- 大img -->
                <div class="circle">
                  <div>
                    <img
                      class="present"
                      src="/img/case-progress1.svg"
                      alt="通報中"
                      v-if="Case.CaseStep == 1"
                    />
                    <img
                      class="present"
                      src="/img/case-progress3.svg"
                      alt="處理中"
                      v-if="Case.CaseStep == 2"
                    />
                    <img
                      class="present"
                      src="/img/case-progress5.svg"
                      alt="已結案"
                      v-if="Case.CaseStep == 3"
                    />
                  </div>
                </div>
                <!-- 標題 -->
                <div class="title">
                  <h2>案件編號：{{ Case.CaseNo }}</h2>
                  <!-- <p v-if="Case.Organizer">
                    承辦單位：{{ Case.Organizer }}
                  </p> -->
                </div>
                <!-- 文字 -->
                <div class="text-box">
                  <div v-if="Case.AppealTime">
                    <p>立案日期</p>
                    ：
                    <p>{{ Case.AppealTime }}</p>
                  </div>
                  <div v-if="Case.FinishTime">
                    <p>結案日期</p>
                    ：
                    <p>{{ Case.FinishTime }}</p>
                  </div>
                  <div v-if="Case.Area">
                    <p>行政區</p>
                    ：
                    <p>{{ Case.Area }}</p>
                  </div>
                  <div v-if="Case.Location">
                    <p>地點</p>
                    ：
                    <p>{{ Case.Location }}</p>
                  </div>
                  <div v-if="Case.Type">
                    <p>類型</p>
                    ：
                    <p>{{ Case.Type }}</p>
                  </div>
                  <div v-if="Case.SubType">
                    <p>事項</p>
                    ：
                    <p>{{ Case.SubType }}</p>
                  </div>
                </div>
                <div
                  class="text-box"
                  style="text-align: right; display: block; padding-right: 20px"
                >
                  <button
                    style="
                      background-color: #c52560;
                      color: #fff;
                      border: none;
                      border-radius: 5px;
                      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
                      padding: 5px 10px;
                      min-width: 120px;
                      margin-right: 10px;
                      transition: 0.3s;
                    "
                    @click="
                      gotoCaseDetail(
                        Case.CaseNo,
                        Case.Sugg_Telno,
                        Case.Sugg_Email
                      )
                    "
                  >
                    查看詳情
                  </button>
                </div>
                <!-- 進度條 -->
                <ul class="progress-box" v-if="false">
                  <li>
                    <div
                      class="circle"
                      :class="
                        CaseIndex == index_case && ShowTab_Style == 1
                          ? 'active'
                          : ''
                      "
                      @click="
                        {
                          CaseIndex = index_case;
                          ShowTab = 1;
                          ShowTab_Style = 1;
                        }
                      "
                    >
                      <div
                        :style="Case.CaseStep < 1 ? 'background: #b5b5b5;' : ''"
                      >
                        <img src="/img/case-progress1.svg" alt="通報" />
                      </div>
                    </div>
                    <p>通報</p>
                  </li>
                  <li>
                    <div
                      class="circle"
                      :class="
                        CaseIndex == index_case && ShowTab_Style == 2
                          ? 'active'
                          : ''
                      "
                      @click="
                        {
                          CaseIndex = index_case;
                          ShowTab = 1;
                          ShowTab_Style = 2;
                        }
                      "
                    >
                      <div
                        :style="Case.CaseStep < 1 ? 'background: #b5b5b5;' : ''"
                      >
                        <img src="/img/case-progress2.svg" alt="立案" />
                      </div>
                    </div>
                    <p>立案</p>
                  </li>
                  <li>
                    <div
                      class="circle"
                      :class="
                        CaseIndex == index_case && ShowTab_Style == 3
                          ? 'active'
                          : ''
                      "
                      @click="
                        {
                          CaseIndex = index_case;
                          ShowTab = 2;
                          ShowTab_Style = 3;
                        }
                      "
                    >
                      <div
                        :style="Case.CaseStep < 2 ? 'background: #b5b5b5;' : ''"
                      >
                        <img src="/img/case-progress3.svg" alt="分案" />
                      </div>
                    </div>
                    <p>分案</p>
                  </li>
                  <li>
                    <div
                      class="circle"
                      :class="
                        CaseIndex == index_case && ShowTab_Style == 4
                          ? 'active'
                          : ''
                      "
                      @click="
                        {
                          CaseIndex = index_case;
                          ShowTab = 2;
                          ShowTab_Style = 4;
                        }
                      "
                    >
                      <div
                        :style="Case.CaseStep < 2 ? 'background: #b5b5b5;' : ''"
                      >
                        <img src="/img/case-progress4.svg" alt="處理情形" />
                      </div>
                    </div>
                    <p>處理情形</p>
                  </li>
                  <li>
                    <div
                      class="circle"
                      :class="
                        CaseIndex == index_case && ShowTab_Style == 5
                          ? 'active'
                          : ''
                      "
                      @click="
                        {
                          CaseIndex = index_case;
                          ShowTab = 3;
                          ShowTab_Style = 5;
                        }
                      "
                    >
                      <div
                        :style="Case.CaseStep < 3 ? 'background: #b5b5b5;' : ''"
                      >
                        <img src="/img/case-progress5.svg" alt="結案" />
                      </div>
                    </div>
                    <p>結案</p>
                  </li>
                </ul>
                <div
                  class="detail"
                  :class="CaseIndex == index_case && ShowTab == 1 ? 'show' : ''"
                  v-if="Case.Step1 && false"
                >
                  <div class="title-box">
                    <h3>{{ Case.Step1.Title }}</h3>
                    <p>{{ Case.Step1.DateTime }}</p>
                  </div>
                  <div class="text">
                    <p style="white-space: pre-line">
                      {{ Case.Step1.Detail }}
                    </p>
                    <div v-if="Case.Step1.State">
                      <p>案件辦理情形</p>
                      <p style="white-space: pre-line">
                        {{ Case.Step1.State }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="detail"
                  :class="CaseIndex == index_case && ShowTab == 2 ? 'show' : ''"
                  v-if="Case.Step2 && false"
                >
                  <div
                    v-for="(Step, index_case_2) in Case.Step2"
                    :key="index_case_2"
                    style="margin-bottom: 15px"
                  >
                    <div class="title-box">
                      <!-- <h3>{{ Step.Title }}</h3> -->
                      <h3 v-if="ShowTab_Style == 3">分案</h3>
                      <h3 v-if="ShowTab_Style == 4">處理情形</h3>
                      <p>{{ Step.DateTime }}</p>
                    </div>
                    <div class="text">
                      <p
                        style="white-space: pre-line"
                        v-if="ShowTab_Style == 4"
                      >
                        {{ Step.Detail }}
                      </p>
                      <div v-if="Step.Organizer">
                        <p>承辦單位</p>
                        <p>{{ Step.Organizer }}</p>
                      </div>
                    </div>
                    <div class="title-box" v-if="Step.Atth_FileNames">
                      <h3>附件</h3>
                    </div>
                    <div class="text" v-if="Step.Atth_FileNames">
                      <div
                        v-for="(Atth_FileName, index_f) in Step.Atth_FileNames"
                        :key="index_f"
                      >
                        <p>附件 {{ index_f + 1 }}</p>
                        <p>
                          <a
                            :href="
                              'https://cmsweb.tainan.gov.tw/WebApi2/api/attachfile/' +
                              Atth_FileName.SerialNo +
                              '?fileName=' +
                              Atth_FileName.FileName +
                              '&caseNo=' +
                              Case.CaseNo +
                              '&suggName=' +
                              Step.Sugg_name +
                              '&organNo=' +
                              Step.OrganNo +
                              '&seqNo=' +
                              Step.SeqNo +
                              '&sNo=' +
                              Step.Sno
                            "
                            traget="_blank"
                            >{{ Atth_FileName.FileName }}</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="detail"
                  :class="CaseIndex == index_case && ShowTab == 3 ? 'show' : ''"
                  v-if="Case.Step3 && false"
                >
                  <div
                    v-for="(Step, index_case_3) in Case.Step3"
                    :key="index_case_3"
                  >
                    <div class="title-box">
                      <h3>{{ Step.Title }}</h3>
                      <p>{{ Step.DateTime }}</p>
                    </div>
                    <div class="text">
                      <p style="white-space: pre-line">
                        {{ Step.Detail }}
                      </p>
                      <!-- <div v-if="Step.State">
                        <p>案件辦理情形</p>
                        <p>{{ Step.State }}</p>
                      </div> -->
                      <div v-if="Step.Organizer">
                        <p>承辦單位</p>
                        <p>{{ Step.Organizer }}</p>
                      </div>
                    </div>
                    <div class="title-box" v-if="Step.Atth_FileNames">
                      <h3>附件</h3>
                    </div>
                    <div class="text" v-if="Step.Atth_FileNames">
                      <div
                        v-for="(Atth_FileName, index_f) in Step.Atth_FileNames"
                        :key="index_f"
                      >
                        <p>附件 {{ index_f + 1 }}</p>
                        <p>
                          <a
                            :href="
                              'https://cmsweb.tainan.gov.tw/WebApi2/api/attachfile/' +
                              Atth_FileName.SerialNo +
                              '?fileName=' +
                              Atth_FileName.FileName +
                              '&caseNo=' +
                              Case.CaseNo +
                              '&suggName=' +
                              Step.Sugg_name +
                              '&organNo=' +
                              Step.OrganNo +
                              '&seqNo=' +
                              Step.SeqNo +
                              '&sNo=' +
                              Step.Sno
                            "
                            traget="_blank"
                            >{{ Atth_FileName.FileName }}</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <!-- 頁碼 -->
            <section class="page" v-if="pageAll > 1">
              <paginate
                :no-li-surround="false"
                :page-count="pageAll"
                :page-range="3"
                :margin-pages="1"
                :click-handler="loadList"
                :prev-text="'<'"
                :next-text="'>'"
                :prev-link-class="''"
                :next-link-class="''"
                :container-class="'pagination'"
                :page-link-class="''"
                :active-class="'active'"
                :active-link-class="'active'"
                :hide-prev-next="false"
              >
              </paginate>
            </section>
          </div>
        </div>
      </section>
    </div>
    <!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import Paginate from "vuejs-paginate";

Vue.component("paginate", Paginate);

export default {
  name: "MemberCase",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      Status: "",
      Cases: null,
      pageAll: 0,
      perPage: 5,
      CaseIndex: -1,
      ShowTab: "",
      ShowTab_Style: 0,
    };
  },
  watch: {
    Status(newVal, oldVal) {
      this.loadList(1);
    },
  },
  beforeCreate: function () {
    if (!$cookies.isKey("open_login_token")) {
      this.$router.push({
        name: "home",
      });
    }
  },
  created: function () {},
  mounted: function () {
    this.loadList(1);
  },
  updated: function () {},
  methods: {
    loadList: function (page) {
      var that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/CaseList",
        type: "post",
        data: {
          Status: that.Status,
          page: page,
          perPage: that.perPage,
          token: $cookies.get("open_login_token"),
        },
        dataType: "json",
        error: function () {},
        success: function (res) {
          if (res.status == 1) {
            that.Cases = res.Cases;
            that.perPage = res.perPage;
            that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
            document.body.scrollTop = 0;
          }
        },
      });
    },
    gotoCaseDetail: function (CaseNo, Sugg_Telno, Sugg_Email) {
      const routeData = this.$router.resolve({
        name: "caseDetail",
        query: {
          caseNo: CaseNo,
          sugg_telno: Sugg_Telno,
          sugg_email: Sugg_Email,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

