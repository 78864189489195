<template>
  <div>
    <Head />

    <div class="wrapper sitemap">
      <section class="breadcrumb-box">
        <div class="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/" title="首頁">首頁</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                網站導覽
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section class="main">
        <div class="container">
          <img class="h1-img" src="/img/h1-sitemap.svg" alt="網站導覽">
          <h1>網站導覽</h1>
          <div class="text">
            <p>
              本網站依照「無障礙網頁開發規範」原則設計，
              遵循無障礙網站設計之規範提供網頁定位點(:::)、鍵盤快速鍵 (Access
              Key) 等設計方式。 下列為本網站使用鍵盤快速鍵 (Access Key)
              操作方式說明：
              <br />
              Alt +U：最上層副選單功能區，移至網頁最上方之項目位置。
              <br />
              Alt +C：主要內容區，此區塊呈現網頁主要內容資訊。
              <br />
              Alt +B：下方功能區塊，包括聯絡方式、交通資訊等。
            </p>
          </div>
          <ul class="sitemap-box" v-if="SiteMapData" v-html="SiteMapData.Content">
          </ul>
        </div>
      </section>
    </div>
    <!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

export default {
  name: "SiteMap",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      SiteMapData: null,
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/SiteMap",
        {
          params: {
          },
        }
      )
      .then((response) => {
        this.SiteMapData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {},
  methods: {},
};
</script>

